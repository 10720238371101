const ENV_URL_NAME = "REACT_APP_API_URL";
const ENV_URL = process.env[ENV_URL_NAME];
if (process.env.NODE_ENV !== "development" && !ENV_URL) {
  throw new Error(
    `NODE_ENV is set to an env other than "development" without ${ENV_URL_NAME} being set. Make sure to set ${ENV_URL_NAME}.`
  );
}

const API_URL = ENV_URL || "http://localhost:4000";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

function buildHeaders() {
  return defaultHeaders;
}

function parseJSON(resp) {
  return resp.json();
}

export function apiPost(url, data, parse = true) {
  const body = JSON.stringify(data);

  const promise = fetch(API_URL + "/" + url, {
    method: "post",
    headers: buildHeaders(),
    body,
  });

  if (parse) return promise.then(parseJSON);
  return promise;
}

export function apiGet(url) {
  return fetch(API_URL + "/" + url, {
    headers: buildHeaders(),
  }).then(parseJSON);
}
